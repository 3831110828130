import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle3, PageTitle2, PageParagraph } from '../../components/PageTitle'
import BenefitsCompounds from '../images/benefitsCompounds.svg'
import BenefitsEasy from '../images/benefitsEasy.svg'
import BenefitsEngaging from '../images/benefitsEngaging.svg'
import BenefitsSecure from '../images/benefitsSecure.svg'
import SalesAdoption from '../images/salesAdoption.png'
import SalesforceDashboard from '../images/salesforceDashboard.png'
import Default from '../../components/Layouts/Default';
import { VideoFrame } from '../../components/VideoFrame';
import DeviceLineup from '../images/deviceLineup.png';
import TeamDash from '../images/teamDash.svg';
import LiveWithCustomer from '../images/liveWithTheCustomer.svg';
import BestSalesforce from '../images/bestSalesforce.svg';
import SalesforceGraph from '../images/salesforceGraph.png';
import SalesforcePackage from '../images/salesforcePackage.png';
import SalesforceAccent from '../images/salesforceAccented.png';
import TeamDashHalf from '../images/teamDashHalf.svg'
import ItMonitoring from '../images/itMonitoring.svg';
import { CenteredImg } from '../../common/Style';




import LibertyRing from '../animations/libertyRing.json'
import DesktopSalesforce from '../images/desktopSalesforce.png';


var lottie

export default class BetterCrmReporting extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Platform</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='businessScene3'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1 / 2, 1 / 2]} pb="2rem">
              <WidthImg src={BestSalesforce} alt="Sales Adoption" />
            </Box>
            <Box width="1">
              <PageTitle as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Unified Analytics For Salesforce
              </PageTitle>
              <PageParagraph as="p" color={colors.white} mb="1.5rem" center>
              Finally, you can answer every sales funnel question in Salesforce
              </PageParagraph>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>


        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={SalesforceDashboard} alt="Salesforce Dashboard" />
            </Box>
            <Box width={[1, 1]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                Get All Your Sales Funnel Analytics Natively In CRM
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
                Today, 90% of sales funnel analysis takes place outside of Salesforce, in spreadsheets, BI platforms, and sales tools. This is because Salesforce’s default configuration wasn’t designed for modern sales analytics.
                <br />
                <br />
                Our software enhances your CRM’s data model and enriched your data to allow you to centralize all aspects of your reporting in one system, including:
                <br />
                <br />
                <Bold>Cadencing Analytics</Bold>
                <Bold>Opportunity Analytics</Bold>
                <Bold>Conversation Intelligence Analytics</Bold>
                <Bold>Revenue Intelligence Analytics</Bold>
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>

    <WrapperSolid color="#292929">
      <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
        <Box width={[1 / 2, 2 / 3]} pb="2rem">
          <CenteredImg src={ItMonitoring} alt="IT Monitoring" />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Title3 as="h2" color={colors.accentSage} bold mb="1.5rem" center>
            Activity Data Enrichment & Native ETL
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem" center>
            No longer do you have to spend time doing data cleanup on Activities that are tied to different objects, or figuring out how to track
            changes in your opportunity funnel.
            <br />
            <br />
            Truly's Salesforce native ETL (Extract, Transform, Load) processes all of your data so you no longer need to run your analyses in Excel, Redshift, Tableau or other tools.
            <br />
            <br />
            As a result, you can make data driven insights accessible to everyone across the organization, in the platform they use every day.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>


    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={TeamDashHalf} alt="Coaching"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          Works With Your Existing Stack
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
             Whether you're capturing activity data through one of Truly's many products or using existing solutions, you can benefit from our salesforce-native approach to reporting.
             <br/> <br />
             Customizable ETL capabilities allow you to normalize and label activity data across multiple providers to create a single reporting plane to analyze.
             <br/><br/>
             Once your data is analyzed, it flows through seamlessly into our prebuilt Salesforce native dashboards.  And our unique approach to modeling data means that you can finally get the power of the most advanced BI tools natively inside your CRM.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>





      </Default>
    )
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Bold = styled.div`
  font-weight: Bold;
`

const Divider = styled.div`
  width: 100%;
  height: 1pt;
  background-color: rgba(255,255,255,0.3);
`
const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
`;